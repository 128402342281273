<template>
	<div>
		<div v-if="sent === false">
			<div class="simple-title">Password Reset<span class="fal fa-paper-plane"></span></div>
			<div class="form-wrapper small">
				<div class="form-container">
					<form-errors :errors="errors"></form-errors>

					<div class="form-title">Password Reset</div>

					<form @submit.prevent="onSubmit">

					<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
					<div class="input-wrapper">
						<div class="input-title">Account Email Address</div>
						<div class="input-box"><input type="text" v-model="form.email"></div>
					</div>

					<submit-button text="Send Password Reset" icon="fa-paper-plane" :loading="formSaving" :has-errors="hasErrors"></submit-button>

					</form>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="form-wrapper small">
				<div class="form-container">
					<div class="input-wrapper">
						<div class="input-title">Email Sent</div>
						<div class="input-box">We have sent you an e-mail with instructions to reset your password.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';
import { required, email } from 'vuelidate/lib/validators';

export default {

	name: 'ForgotPasswordPage',

	mixins: [ FormMixin ],

	data() {
		return {
			form: {},
			sent: false,
			errors: {},
		};
	},

	validations: {
		form: {
			email: { required, email },
		},
	},

	methods: {

		async onSubmit() {
			this.submit(async () => {
				await this.$api.post('/password/email', this.form);

				this.sent = true;
			}, () => {
				this.$store.dispatch('errorToast', 'Unable to process request');
			});
		},

	}
};

</script>
